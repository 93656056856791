<template>
  <div class="home d-flex fill-height flex-column">
    <canvas class="d-none" ref="textCanvas" height="150px"></canvas>
    <v-overlay absolute :value="needLicence || needLicenceLink" opacity="0.8">
      <v-card v-if="needLicence" max-width="500px">
        <v-card-title>Vous n'avez pas de licence active</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>
            Pour continuer à utiliser Focalia, veuillez vérifier vos licences.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-auto" text color="success" :to="{ name: 'Licences' }"
            >Activer une licence</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else-if="needLicenceLink" max-width="500px">
        <v-card-title>Associez cet appareil à une licence</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>
            Cet appareil doit être associé à une adresse et une licence.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-auto" text color="success" :to="{ name: 'Device' }"
            >Configurer cet appareil</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-overlay>
    <v-row>
      <v-col sm="6" lg="5">
        <photo-editor
          height="100%"
          min-height="515px"
          @croppedUrl="photoEdited($event)"
          @reset="resetPhotoSettings"
          :settings="photoSettings"
          :data="document"
          :disabled="documentValidated"
          :finished="documentValidated"
        ></photo-editor>
      </v-col>
      <v-col sm="6" lg="3" class="flex-column d-flex">
        <v-row style="/*height: 30px*/">
          <v-col>
            <v-card height="100%">
              <v-tabs grow v-model="photoTab">
                <v-tab>Usage</v-tab>
                <v-tab>Optimisations</v-tab>
              </v-tabs>
              <v-card-text>
                <v-tabs-items v-model="photoTab">
                  <v-tab-item>
                    <v-radio-group
                      v-model="usage"
                      :readonly="documentValidated"
                    >
                      <v-radio
                        label="Permis de conduire"
                        value="PERMIS_CONDUIRE"
                      ></v-radio>
                      <v-radio
                        label="Titre de sejour étudiants étrangers"
                        value="DOCUMENT_ETRANGER"
                      ></v-radio>
                      <v-radio
                        label="Carte Nationale d'Identité"
                        value="CNI"
                        disabled
                      ></v-radio>
                      <v-radio
                        label="Passeport"
                        value="PASSEPORT"
                        disabled
                      ></v-radio>
                      <v-radio
                        class="d-none"
                        label="CNI et Passeport (procédure jointe)"
                        value="PASSEPORT_CNI"
                        disabled
                      ></v-radio>
                    </v-radio-group>
                  </v-tab-item>
                  <v-tab-item>
                    <v-btn
                      x-small
                      color="primary"
                      @click="resetPhotoSettings"
                      :disabled="documentValidated"
                    >
                      <v-icon left x-small>mdi-refresh</v-icon>
                      Réinitialiser
                    </v-btn>
                    <v-row>
                      <v-col
                        class="flex-grow-0 opti-labels"
                        style="min-width: 90px"
                      >
                        <div class="text-caption">Luminosité</div>
                        <div class="text-caption">Contrast</div>
                        <div class="text-caption">Gamma</div>
                        <div class="text-caption">Saturation</div>
                        <div class="text-caption">Exposition</div>
                      </v-col>
                      <v-col class="flex-grow-1">
                        <v-slider
                          v-model="photoSettings.brightness"
                          min="-30"
                          max="30"
                          thumb-label
                          hide-details
                          :disabled="documentValidated"
                        ></v-slider>

                        <v-slider
                          v-model="photoSettings.contrast"
                          min="-30"
                          max="30"
                          thumb-label
                          hide-details
                          :disabled="documentValidated"
                        ></v-slider>

                        <v-slider
                          v-model="photoSettings.gamma"
                          min="0"
                          max="4"
                          step="0.1"
                          thumb-label
                          hide-details
                          :disabled="documentValidated"
                        ></v-slider>

                        <v-slider
                          v-model="photoSettings.saturation"
                          min="-50"
                          max="50"
                          thumb-label
                          hide-details
                          dense
                          :disabled="documentValidated"
                        ></v-slider>

                        <v-slider
                          v-model="photoSettings.exposure"
                          min="-100"
                          max="100"
                          thumb-label
                          hide-details
                          :disabled="documentValidated"
                        ></v-slider>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card height="100%">
              <v-card-title class="text-overline pt-1">Signature</v-card-title>
              <v-card-text class="pb-0">
                <div v-if="remoteSignatureInProgress || signatureFinished">
                  <div class="text-caption" v-if="!signatureFinished">
                    Signature du client en cours...
                  </div>
                  <div v-else class="text-caption success--text">
                    Signature du client ajoutée
                    <v-icon color="success" small>mdi-check-circle</v-icon>
                  </div>
                  <drawer
                    v-if="
                      !signatureFinished ||
                        $store.state.canvas.canvasData !== null
                    "
                    client
                    style="max-width: 100%"
                    :clear.sync="clearSignature"
                    @end="signatureUrlCreated"
                  ></drawer>
                  <div
                    v-else-if="signatureFinished && signatureUrl"
                    class="mb-2"
                  >
                    <v-img
                      :src="signatureUrl"
                      width="100%"
                      contain
                      style="border: 1px solid black"
                    ></v-img>
                  </div>
                  <div>
                    <v-btn
                      v-if="remoteSignatureInProgress"
                      small
                      color="error"
                      @click="cancelRemoteSign"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      color="error"
                      @click="removeSignature"
                      :disabled="documentValidated"
                    >
                      <v-icon left small>mdi-delete</v-icon>
                      Effacer
                    </v-btn>
                  </div>
                </div>
                <div class="text-center" v-else>
                  <v-btn color="primary" class="my-1" @click="startRemoteSign">
                    <v-icon left>mdi-access-point</v-icon>
                    Sur l'appareil distant
                  </v-btn>
                  <v-switch
                    v-model="askEmail"
                    label="Demander l'adresse email"
                    class="mt-0 d-inline-block"
                    hide-details
                  ></v-switch>
                  <v-divider class="my-2"></v-divider>
                  <div>
                    <v-btn
                      color="primary"
                      class="my-1"
                      outlined
                      @click="signatureDialog = true"
                    >
                      Sur cet appareil
                    </v-btn>
                  </div>

                  <v-btn
                    color="primary"
                    class="my-1"
                    outlined
                    @click="$refs.signatureFileInput.click()"
                  >
                    <v-icon left>mdi-upload</v-icon>
                    Importer
                  </v-btn>
                  <div class="text-center text--disabled text-caption">
                    521px x 134px
                  </div>
                  <input
                    type="file"
                    accept="image/jpeg,image/png"
                    ref="signatureFileInput"
                    class="d-none"
                    @change="onImportSignature($event)"
                  />
                  <img
                    :src="signatureFileInputImg"
                    alt=""
                    ref="signatureFileInputImg"
                    class="d-none"
                  />
                </div>
              </v-card-text>
              <v-card-text
                class="pt-0 text-center"
                v-if="remoteSignatureInProgress"
              >
                <v-switch
                  v-model="askEmail"
                  label="Demander l'adresse email"
                  class="mt-0 d-inline-block"
                ></v-switch>
              </v-card-text>
              <v-dialog
                v-model="signatureDialog"
                max-width="600px"
                fullscreen
                persistent
              >
                <v-card style="height: 300px">
                  <v-card-title>Signature</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="mt-2 d-flex justify-center align-center">
                    <div>
                      <div>Dessinez votre signature :</div>
                      <drawer
                        :clear.sync="clearSignature"
                        :validate.sync="validateSignature"
                        @end="signatureUrlCreated"
                        style="max-width: 100%"
                      ></drawer>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="error" @click="signatureDialog = false">
                      Annuler
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="default"
                      @click="clearSignatureCanvas"
                      class="mr-2"
                    >
                      Effacer
                    </v-btn>
                    <v-btn color="success" @click="validateSignatureDialog"
                      >Valider
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="4" class="flex-column d-flex">
        <v-row>
          <v-col sm="6" md="8" lg="12">
            <v-card height="100%">
              <v-card-title class="text-overline pt-1">Planche</v-card-title>
              <v-card-text>
                <v-responsive :aspect-ratio="15 / 10" content-class="board-img">
                  <v-overlay absolute v-show="documentLoading">
                    <v-progress-circular
                      value="1"
                      indeterminate
                      :size="70"
                      :width="7"
                    ></v-progress-circular>
                  </v-overlay>
                  <v-img :src="boardUrl" width="100%" contain ref="board">
                    <!--                    <v-img
                      :src="boardUrl"
                      width="100%"
                      contain
                      style="position:absolute;width: 100%;"
                      ref="board"
                    ></v-img>-->
                    <template #placeholder>
                      <v-overlay absolute>
                        <v-progress-circular
                          value="1"
                          indeterminate
                          :size="70"
                          :width="7"
                        ></v-progress-circular>
                      </v-overlay>
                    </template>
                  </v-img>
                </v-responsive>
                <v-text-field
                  label="Adresse email du client"
                  v-model="email"
                  class="mt-2"
                  type="email"
                  autocomplete="off"
                  @input="emailEdited"
                  outlined
                  :hide-details="emailError === null"
                  dense
                  :readonly="documentValidated"
                  :error-messages="emailError"
                ></v-text-field>
                <v-switch
                  label="Envoyer le code ephoto par email"
                  hide-details
                  v-model="sendByEmail"
                  dense
                  class="mt-0"
                  :readonly="documentValidated"
                ></v-switch>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="6" md="4" lg="12">
            <v-card>
              <v-card-title class="text-overline pt-1 pb-1"
                >Validation
              </v-card-title>
              <v-card-text style="">
                <template v-if="!documentValidated">
                  <div class="text-caption">
                    <div>
                      <span class="font-weight-medium">Photo :</span>
                      <span class="ml-1" v-html="photoState"></span>
                    </div>
                    <div>
                      <span class="font-weight-medium">Signature :</span>
                      <span class="ml-1" v-html="signatureState"></span>
                    </div>
                    <div>
                      <span class="font-weight-medium">Code ephoto :</span>
                      <span class="ml-1" v-html="ephotoState"></span>
                    </div>
                    <div v-if="Object.keys(unhandledErrors).length">
                      <span class="font-weight-medium">Erreur(s) :</span>
                      <div
                        v-for="(error, index) of unhandledErrors"
                        :key="index"
                        class="error--text"
                      >
                        {{ error[0] }}
                      </div>
                    </div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex justify-center flex-wrap">
                    <v-btn
                      class="ma-1"
                      color="success"
                      @click="validateDocument"
                      :disabled="!canValidate"
                      :loading="
                        sendDocumentLoading ||
                          sendPhotoLoading ||
                          sendSignatureLoading
                      "
                    >
                      Valider
                    </v-btn>
                    <v-btn
                      class="ma-1"
                      color="primary"
                      outlined
                      @click="exitDocumentDialog = true"
                      >Créer une autre planche
                    </v-btn>
                  </div>
                </template>
                <div class="d-flex justify-center flex-wrap" v-else>
                  <v-row>
                    <v-col
                      class="py-0 justify-center d-flex flex-column align-center mb-1"
                    >
                      <v-select
                        label="Quantité"
                        v-if="false"
                        :items="Array.from({ length: 5 }, (_, i) => i + 1)"
                        outlined
                        hide-details
                        dense
                        class="mb-1"
                        style="width: 150px"
                        v-model="printQuantity"
                      ></v-select>
                      <v-btn color="primary" @click="printBoard">
                        <v-icon left>mdi-printer</v-icon>
                        Imprimer
                      </v-btn>
                    </v-col>
                    <v-col
                      class="py-0 justify-center d-flex flex-column align-center"
                    >
                      <v-btn
                        color="primary"
                        outlined
                        :href="boardUrl"
                        download="planche.png"
                        tag="a"
                      >
                        <v-icon left>mdi-download</v-icon>
                        Exporter
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mt-1"
                        outlined
                        @click="duplicateDocument"
                      >
                        <v-icon left>mdi-content-duplicate</v-icon>
                        Dupliquer
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mt-1"
                        outlined
                        @click="newDocument"
                      >
                        <v-icon left>mdi-plus-circle-outline</v-icon>
                        Créer une autre planche
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-dialog v-model="exitDocumentDialog" width="400px">
            <v-card>
              <v-card-title>Cette planche n'est pas terminée</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="my-2">Que souhaitez-vous faire ?</div>
                <div class="d-flex justify-center flex-wrap">
                  <v-btn dark class="ma-1" small @click="newDocument">
                    <v-icon left small>mdi-content-save</v-icon>
                    Sauvegarder le brouillon
                  </v-btn>
                  <v-btn
                    color="error"
                    class="ma-1"
                    small
                    @click="deleteCurrentDocument"
                  >
                    <v-icon left small>mdi-delete</v-icon>
                    Supprimer
                  </v-btn>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn text @click="exitDocumentDialog = false">Annuler</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PhotoEditor from '@/components/PhotoEditor.vue'
import mergeImages from 'merge-images'
import Drawer from '@/components/Drawer.vue'
import QRCode from 'qrcode'
import Cropper from 'cropperjs'
import { requestService } from '@/services/request.service'
import Validation from '@/components/mixins/Validation'

const defaultPhotoSettings = {
  brightness: 0,
  contrast: 0,
  gamma: 1,
  saturation: 0,
  exposure: 0,
}

export default {
  name: 'Home',
  components: { Drawer, PhotoEditor },
  mixins: [Validation],
  data() {
    return {
      photoUrl: null,
      photoTab: 0,
      photoSettings: Object.assign({}, defaultPhotoSettings),
      usage: 'PERMIS_CONDUIRE',
      boardUrl: null,
      lazyBoardUrl: null,
      signatureUrl: null,
      addressTextUrl: null,
      codeEphotoTextUrl: null,
      URLTextUrl: null,
      qrCodeImageUrl: null,
      photoData: {},
      postId: 'a1',
      remoteSignatureInProgress: false,
      signatureFinished: false,
      clearSignature: false,
      askEmail: true,
      sendByEmail: true,
      email: '',
      signatureDialog: false,
      validateSignature: false,
      exitDocumentDialog: false,
      documentId: null,
      documents: null,
      document: null,
      codeEphoto: null,
      documentValidated: false,
      printQuantity: 1,
      signatureFileInput: null,
      signatureFileInputImg: null,
      photoUid: null,
      signatureUid: null,
      sendDocumentLoading: false,
      sendPhotoLoading: false,
      sendPhotoError: false,
      sendSignatureLoading: false,
      sendSignatureError: false,
      urlText: null,
      formPhoto: {
        file: { value: '' },
      },
      formSignature: {
        file: { value: '' },
      },
      needLicence: false,
      needLicenceLink: false,
      savedAddressText: null,
      currentAddressText: null,
      documentLoading: false,
      creationDate: null,
      checkLicencesTimeout: null,
      emailError: null,
    }
  },
  computed: {
    canValidate() {
      return this.signatureFinished && this.photoData.url
    },
    photoState() {
      if (this.photoUid !== null) {
        return '<span class="success--text">Envoyée</span>'
      }
      if (this.sendPhotoLoading) {
        return 'Envoi en cours...'
      }
      if (this.formPhoto.file.errors && this.formPhoto.file.errors.file) {
        return `<span class="error--text">${this.formPhoto.file.errors.file[0]}</span>`
      }
      if (this.photoData.url) {
        return '<span class="success--text">Prête</span>'
      }
      return 'Non ajoutée'
    },
    signatureState() {
      if (this.signatureUid !== null) {
        return '<span class="success--text">Envoyée</span>'
      }
      if (this.sendSignatureLoading) {
        return 'Envoi en cours...'
      }
      if (
        this.formSignature.file.errors &&
        this.formSignature.file.errors.file
      ) {
        return `<span class="error--text">${this.formSignature.file.errors.file[0]}</span>`
      }
      if (this.signatureUrl) {
        return '<span class="success--text">Prête</span>'
      }
      return 'Non ajoutée'
    },
    ephotoState() {
      if (this.sendDocumentLoading) {
        return 'Création en cours...'
      }
      if (this.photoData.url && this.signatureUrl) {
        return 'En attente de validation'
      }
      return 'La photo et la signature doivent être terminées'
    },
    addressText() {
      const address =
        this.$store.state.currentAddress || this.$root.get('currentAddress')
      if (address === null) {
        return ''
      }
      return `${address.name}, ${address.address}, ${address.postal_code} ${address.city}. Tel. : ${address.phone}`
    },
  },
  methods: {
    getCurrentDocumentId() {
      console.log('currentDocumentId start update')
      if (this.$route.params.id) {
        console.log('currentDocumentId use route')
        return this.$route.params.id
      }
      console.log('currentDocumentId use storage')
      const documentId = this.$root.get('currentDocumentId')
      if (documentId !== null && this.$route.params.id !== documentId) {
        this.$router.replace({
          name: 'Document',
          params: { id: documentId },
        })
      }

      return documentId
    },
    resetPhotoSettings() {
      this.$set(this, 'photoSettings', Object.assign({}, defaultPhotoSettings))
    },
    photoEdited(photoData) {
      this.photoData = photoData
      this.renderBoard()
      this.saveDocument()
    },
    renderBoard() {
      const images = [{ src: '/img/Planche-vide.png', x: 0, y: 0 }]
      let photoData = this.photoData
      if (this.documentValidated) {
        photoData = this.document
      }
      if (photoData.url) {
        images.push(
          { src: photoData.url, x: 40, y: 40 },
          { src: photoData.url, x: 85 + photoData.width, y: 40 },
          { src: photoData.url, x: 40, y: 80 + photoData.height },
          {
            src: photoData.url,
            x: 85 + photoData.width,
            y: 80 + photoData.height,
          },
        )
      }
      if (this.addressTextUrl) {
        images.push({ src: this.addressTextUrl, x: 1000, y: 200 })
      }
      if (this.signatureUrl) {
        images.push({ src: this.signatureUrl, x: 1100, y: 980 })
      }
      if (this.URLTextUrl) {
        images.push({ src: this.URLTextUrl, x: 1000, y: 430 })
      }
      if (this.qrCodeImageUrl) {
        images.push({ src: this.qrCodeImageUrl, x: 1250, y: 540 })
      } else {
        console.log('no qrcode')
      }
      if (this.codeEphotoTextUrl) {
        images.push({ src: this.codeEphotoTextUrl, x: 1040, y: 872 })
      }
      console.log('start merging...')
      mergeImages(images).then(b64 => {
        console.log('images merged')
        this.documentLoading = false
        this.boardUrl = b64
        // setTimeout(() => {
        //   this.lazyBoardUrl = this.boardUrl
        // }, 100)
      })
    },
    makeAddressText() {
      if (this.$refs.textCanvas === undefined || !this.addressText) {
        return
      }
      let text = ''
      if (this.savedAddressText !== null && this.documentValidated) {
        text = this.savedAddressText
      } else {
        this.creationDate = new Date()
        const date = this.formatDate(this.creationDate, true)
        text = `Fait le ${date} chez ${this.addressText}`
      }
      this.currentAddressText = text

      const context = this.$refs.textCanvas.getContext('2d')
      context.canvas.width = 720
      context.font = '35px Courier'
      const lines = this.getLines(context, text, context.canvas.width)
      let y = 30
      for (const line of lines) {
        context.fillText(line, 0, y)
        y += 35
      }
      this.addressTextUrl = context.canvas.toDataURL()
      // this.renderBoard()
    },
    formatDate(date, hour = false) {
      return new Intl.DateTimeFormat('fr', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(date)
    },
    async makeURLText() {
      if (this.urlText === null || this.urlText === undefined) {
        // this.renderBoard()
        return Promise.resolve(false)
      }
      const context = this.$refs.textCanvas.getContext('2d')
      context.canvas.width = 720
      context.font = '40px Courier'
      context.fillStyle = '#8a2c48'
      context.fillText(this.urlText, 0, 30)
      this.URLTextUrl = context.canvas.toDataURL()

      this.qrCodeImageUrl = await QRCode.toDataURL(this.urlText, {
        errorCorrectionLevel: 'Q',
        width: 240,
        color: {
          dark: '#8a2c48', // Blue dots
          light: '#0000', // Transparent background
        },
      })
      this.renderBoard()
      return Promise.resolve(true)
    },
    async makeEphotoText() {
      if (this.codeEphoto === null) {
        return
      }
      const context = this.$refs.textCanvas.getContext('2d')
      context.canvas.width = 720
      context.font = 'bold 49px Courier'
      context.fillStyle = '#000'
      context.fillText(this.codeEphoto, 0, 30)
      this.codeEphotoTextUrl = context.canvas.toDataURL()
    },
    getLines(ctx, text, maxWidth) {
      const words = text.split(' ')
      const lines = []
      let currentLine = words[0]

      for (let i = 1; i < words.length; i++) {
        const word = words[i]
        const width = ctx.measureText(currentLine + ' ' + word).width
        if (width < maxWidth) {
          currentLine += ' ' + word
        } else {
          lines.push(currentLine)
          currentLine = word
        }
      }
      lines.push(currentLine)
      return lines
    },
    startRemoteSign() {
      this.clearSignatureCanvas()
      this.$store.dispatch('socket/emit', {
        event: 'need_signature',
        data: { askEmail: this.askEmail },
      })
      this.remoteSignatureInProgress = true
    },
    cancelRemoteSign() {
      this.$store.dispatch('socket/emit', {
        event: 'cancel_signature',
      })
      this.remoteSignatureInProgress = false
    },
    removeSignature() {
      this.clearSignatureCanvas()
      this.signatureFinished = false
      this.signatureUrl = null
      this.renderBoard()
      this.remoteSignatureInProgress = false
    },
    async initRemoteHandling() {
      const postId = this.$root.get('currentLicenceId')
      await this.$store.dispatch('socket/connect', postId)
      await this.$store.dispatch('socket/on', {
        event: 'reset_signature',
        callback: () => {
          this.clearSignatureCanvas()
        },
      })
      await this.$store.dispatch('socket/on', {
        event: 'set_email',
        callback: data => {
          this.email = data
        },
      })
    },
    clearSignatureCanvas() {
      this.clearSignature = true
      this.signatureFinished = false
      this.signatureUrl = null
      this.saveDocument()
      this.$store.commit('canvas/canvasData', null)
      this.$nextTick(() => (this.clearSignature = false))
    },
    signatureUrlCreated({ signatureUrl, signatureData }) {
      this.signatureFinished = true
      this.remoteSignatureInProgress = false
      this.signatureUrl = signatureUrl
      this.renderBoard()
    },
    validateSignatureDialog() {
      this.validateSignature = true
      this.signatureDialog = false
    },
    emailEdited() {
      this.$store.dispatch('socket/emit', {
        event: 'set_email',
        data: this.email,
      })
    },
    async loadStorage() {
      console.log('loadStorage')
      // const askEmail = localStorage.getItem('askEmail')
      // const sendByEMail = localStorage.getItem('sendByEMail')
      const askEmail = this.$root.get('askEmail')
      const sendByEMail = this.$root.get('sendByEMail')
      if (askEmail) {
        this.askEmail = askEmail === 'true'
      }
      if (sendByEMail) {
        this.sendByEmail = sendByEMail === 'true'
      }
      console.log('storage loaded')
    },
    saveDocument() {
      return new Promise(resolve => {
        this.makeAddressText()
        if (this.$store.state.documents[this.documentId] === undefined) {
          this.$store.state.documents[this.documentId] = {}
        }
        const document = this.$store.state.documents[this.documentId]
        if (document['documentValidated']) {
          return resolve()
        }
        document['photoData'] = this.photoData
        document['photoSettings'] = this.photoSettings
        document['signatureFinished'] = this.signatureFinished
        document['signatureUrl'] = this.signatureUrl
        document['email'] = this.email
        document['documentValidated'] = this.documentValidated
        document['codeEphoto'] = this.codeEphoto
        document['urlText'] = this.urlText
        document['usage'] = this.usage
        document['addressText'] = this.currentAddressText
        document['creationDate'] = this.creationDate
        // localStorage.setItem(
        //   'documents',
        //   JSON.stringify(this.$store.state.documents),
        // )
        // localStorage.setItem('currentDocumentId', this.documentId)
        this.$store.state.documents = Object.fromEntries(
          Object.entries(this.$store.state.documents)
            .reverse()
            .slice(0, 5)
            .reverse(),
        )
        this.$root.set('documents', this.$store.state.documents)
        this.$root.set('currentDocumentId', this.documentId)
        console.log('Document saved')
        resolve()
      })
    },
    loadDocument(documentId) {
      console.log('start loadDocument')
      this.documentLoading = true
      // const routeDocumentId = documentId || this.$route.params.id
      // const storageDocumentId = localStorage.getItem('currentDocumentId')
      // const currentDocumentId = documentId || this.currentDocumentId
      const currentDocumentId = documentId || this.getCurrentDocumentId()
      console.log('currentDocumentId ready loadDocument')
      if (currentDocumentId) {
        this.documentId = currentDocumentId
      } else {
        this.documentId = new Date().getTime()
        this.$router.replace({
          name: 'Document',
          params: { id: this.documentId },
        })
      }
      console.log('documentId ready loadDocument')

      this.$store.commit('canvas/canvasData', null)

      if (this.$store.state.documents[this.documentId] === undefined) {
        this.renderBoard()
        return
      }
      const document = this.$store.state.documents[this.documentId]
      this.document = document.photoData
      if (document.photoSettings) {
        this.photoSettings = document.photoSettings
      }
      if (document.signatureFinished !== undefined) {
        this.signatureFinished = document.signatureFinished
      }
      if (document.signatureUrl) {
        this.signatureUrl = document.signatureUrl
      }
      if (document.email) {
        this.email = document.email
      }
      if (document.documentValidated !== undefined) {
        this.documentValidated = document.documentValidated
      }
      this.codeEphoto = document.codeEphoto
      this.urlText = document.urlText
      this.usage = document.usage
      this.savedAddressText = document.addressText
      this.creationDate = document.creationDate

      console.log('pre-end loadDocument')
      this.$nextTick(() => {
        this.$root.set('currentDocumentId', this.documentId)
      })
      this.makeEphotoText()
      this.makeURLText().then(created => {
        if (!created) {
          console.log('not created')
          this.renderBoard()
        }
      })
      console.log('end loadDocument')
    },
    duplicateDocument() {
      const newDocumentId = new Date().getTime()
      this.$store.state.documents[newDocumentId] = Object.assign(
        {},
        this.$store.state.documents[this.documentId],
      )
      const newDocument = this.$store.state.documents[newDocumentId]
      newDocument.documentValidated = false
      newDocument.codeEphoto = null
      newDocument.urlText = null
      newDocument.addressText = null
      newDocument.email = ''
      this.$root.set('currentDocumentId', newDocumentId)
      this.$root.set('documents', this.$store.state.documents)
      this.$router.replace({ name: 'Document', params: { id: newDocumentId } })
    },
    async loadDocumentList() {
      this.$store.state.documents = (await this.$root.get('documents')) || {}
      this.loadDocument()
    },
    deleteCurrentDocument() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.$store.state.documents,
          this.documentId,
        )
      ) {
        this.$delete(this.$store.state.documents, this.documentId)
        this.$root.set('documents', this.$store.state.documents)
        this.$root.set('currentDocumentId', null)
        // localStorage.removeItem('currentDocumentId')
      }
      this.newDocument()
    },
    createNewDocument() {
      if (this.documentValidated) {
        this.newDocument()
      } else {
        this.exitDocumentDialog = true
      }
    },
    newDocument() {
      // localStorage.removeItem('currentDocumentId')
      this.$root.set('currentDocumentId', null)
      this.$router.replace({ name: 'Document' })
      this.$root.$emit('reload')
    },
    async validateDocument() {
      this.$set(this, 'unhandledErrors', {})
      this.emailError = null
      if (
        this.email &&
        this.email.length &&
        this.sendByEmail &&
        !this.validateEmail(this.email)
      ) {
        return (this.emailError = "Cette adresse email n'est pas valide")
      }
      this.sendDocumentLoading = true
      const photoFormData = new FormData()
      const photoBlob = await (await fetch(this.photoData.url)).blob()
      photoFormData.append('file', photoBlob)
      this.sendPhotoLoading = true
      this.sendPhotoError = false
      requestService
        .post('/api/order/upload/photo', photoFormData)
        .then(response => {
          this.photoUid = response.data.uid
          this.submitDocument()
        })
        .catch(({ error, validationFailed }) => {
          this.sendDocumentLoading = false
          if (validationFailed) {
            this.handleServerErrors(error, this.formPhoto)
          }
          this.sendPhotoError = true
        })
        .finally(() => {
          this.sendPhotoLoading = false
        })
      const signatureFormData = new FormData()
      const signatureBlob = await (await fetch(this.signatureUrl)).blob()
      signatureFormData.append('file', signatureBlob)
      this.sendSignatureLoading = true
      this.sendSignatureError = false
      requestService
        .post('/api/order/upload/signature', signatureFormData)
        .then(response => {
          this.signatureUid = response.data.uid
          this.submitDocument()
        })
        .catch(({ error, validationFailed }) => {
          this.sendDocumentLoading = false
          console.log(error)
          if (validationFailed) {
            this.handleServerErrors(error, this.formSignature)
          }
          this.sendSignatureError = true
        })
        .finally(() => {
          this.sendSignatureLoading = false
        })
    },
    submitDocument() {
      if (this.photoUid === null || this.signatureUid === null) {
        return
      }
      requestService
        .post('/api/order/', {
          photo: this.photoUid,
          signature: this.signatureUid,
          antsUsage: this.usage,
          customerEmail: this.email,
          sendToCustomer: this.sendByEmail,
          subscription: this.$root.get('currentLicenceId'),
        })
        .then(response => {
          this.documentLoading = true
          this.codeEphoto = response.data.codeEphoto
          this.urlText = response.data.customerUrl
          this.makeEphotoText()
          this.makeURLText().then(created => {
            if (!created) {
              this.renderBoard()
            }
          })
          this.documentValidated = true
          this.sendDocumentLoading = false
          this.document = this.photoData
          // this.renderBoard()
          this.saveDocument().then(() => {
            this.documentValidated = true
          })
        })
        .catch(({ error, validationFailed }) => {
          console.log(error)
          if (validationFailed) {
            this.handleServerErrors(error, this.formSignature)
          }
        })
        .finally(() => (this.sendDocumentLoading = false))
    },
    printBoard() {
      const popup = window.open()
      const html = `<html lang="fr"><head><title>Planche</title></head>
        <body style="margin: 0">
            <img onload="window.print()" src="${this.boardUrl}" style="width:150mm;height: 100mm;" alt="Planche"/>
        </body></html>`
      popup.document.write(html)
      popup.document.close()
      // popup.focus() //required for IE
      // popup.print()
    },
    onImportSignature(event) {
      this.signatureFileInput =
        event.target.files[0] || event.dataTransfer.files[0]
      this.signatureFileInputImg = URL.createObjectURL(this.signatureFileInput)
      this.$nextTick(() => {
        const cropper = new Cropper(this.$refs.signatureFileInputImg, {
          aspectRatio: 521 / 134,
          autoCropArea: 1,
          autoCrop: true,
          rotatable: false,
          scalable: false,
          movable: false,
          cropBoxMovable: false,
          zoomable: false,
          viewMode: 2,
          crop: () => {
            this.signatureUrl = cropper
              .getCroppedCanvas({ width: 521, height: 134 })
              .toDataURL()
            this.signatureFinished = true
            this.renderBoard()
          },
        })
      })
    },
    async checkLicences() {
      await requestService
        .get('/api/user/licences')
        .then(response => {
          if (!response.success) {
            return
          }
          const usableLicencesId = response.usableLicencesId
          const addressesId = response.addressesId
          this.$store.commit('account/setUsableLicencesId', usableLicencesId)
          this.$store.commit('account/setAddressesId', addressesId)
          if (usableLicencesId.length === 0) {
            this.needLicence = true
            return
          }
          let currentAddressId = this.$root.get('currentAddressId')
          if (currentAddressId === null) {
            if (addressesId.length === 1) {
              currentAddressId = addressesId[0]
            } else {
              this.needLicenceLink = true
              return
            }
          }
          return currentAddressId
        })
        .then(currentAddressId => {
          requestService
            .get(`/api/address/${currentAddressId}`)
            .then(address => {
              this.$store.state.currentAddress = address
              this.$root.set('currentAddressId', currentAddressId)
              this.$root.set('currentAddress', address)
              let currentLicenceId = this.$root.get('currentLicenceId')
              if (currentLicenceId === null) {
                if (address.subscriptions.length === 0) {
                  return (this.needLicence = true)
                } else if (address.subscriptions.length === 1) {
                  currentLicenceId = address.subscriptions[0].id
                } else {
                  return (this.needLicenceLink = true)
                }
              }
              currentLicenceId = parseInt(currentLicenceId)
              const currentSubscription = address.subscriptions.find(
                subscription => subscription.id === currentLicenceId,
              )
              if (
                currentSubscription === undefined ||
                !currentSubscription.usable
              ) {
                return (this.needLicenceLink = true)
              }
              this.$root.set('currentLicenceId', currentLicenceId)
              this.$store.state.currentLicenceId = currentLicenceId
            })
            .catch(() => {
              this.$root.set('currentAddressId', null)
              // localStorage.removeItem('currentAddressId')
              this.needLicenceLink = true
            })
        })
    },
  },
  watch: {
    askEmail(value) {
      this.$store.dispatch('socket/emit', {
        event: 'ask_email',
        data: value,
      })
      this.$root.set('askEmail', value)
    },
    sendByEmail(value) {
      this.$root.set('sendByEMail', value)
    },
    signatureFinished() {
      this.saveDocument()
    },
  },
  mounted() {
    this.loadStorage()
    this.makeAddressText()
    this.makeURLText()
    this.makeEphotoText()
    this.initRemoteHandling()
    this.loadDocumentList()
    this.checkLicences()
  },
  beforeRouteUpdate(to, from, next) {
    this.$root.$emit('reload')
    this.$nextTick(next)
  },
}
</script>

<style lang="scss" scoped>
.image-time {
  font-size: 0.7rem;
  color: white;
  background-color: #00000059;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.opti-labels > div {
  height: 32px;
  display: flex;
  align-items: center;
}
::v-deep {
  .board-img {
    border: 1px solid #acacac;
  }
}
</style>
