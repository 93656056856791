<template>
  <v-row class="justify-center align-self-start">
    <v-col cols="12" md="8" lg="6">
      <v-card outlined>
        <v-card-title class="grey lighten-3 flex-column align-start">
          <div>Inscription</div>
          <div class="text-caption text-break">
            Ces informations seront utilisées pour votre compte photographe
            Focalia ainsi que pour votre agrément auprès de l'Agence Nationale
            des Titres Sécurisés. Les informations désignées comme publiques
            seront visibles sur le site de l'ANTS.
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <div class="subtitle-2">Choisissez vos identifiants</div>
            <v-row>
              <v-col cols="12" sm="6">
                <text-field
                  label="Email*"
                  type="email"
                  :field="form.email"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Mot de passe*"
                  type="password"
                  :field="form.password"
                  outlined
                  dense
                ></text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <div class="subtitle-2">Entreprise</div>
            <v-row>
              <v-col cols="12" sm="6">
                <text-field
                  label="Raison sociale*"
                  :field="form.company.name"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="SIRET*"
                  :field="form.company.siret"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Prénom du dirigeant*"
                  :field="form.company.ownerFirstName"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Nom du dirigeant*"
                  :field="form.company.ownerLastName"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Email (publique)*"
                  :field="form.company.contactEmail"
                  type="email"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Site web"
                  :field="form.company.website"
                  type="url"
                  outlined
                  hint="Facultatif"
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-file-input
                  label="Extrait Kbis*"
                  v-model="kbisFile.value"
                  :error-messages="getErrors(form.company.kbis.errors)"
                  @change="onFieldChange(kbisFile)"
                  outlined
                  prepend-icon=""
                  append-outer-icon="mdi-file-document"
                  accept="image/*,.pdf"
                  hint="Extrait KBIS ou équivalent permettant de vérifier votre numéro NAF ou APE, afin de confirmer que votre domaine d'activité correspond à la photographie."
                  persistent-hint
                  dense
                ></v-file-input>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <div class="subtitle-2">
              Adresse
              <span class="font-weight-regular">
                (si vous avez plusieurs adresses, vous pourrez en ajouter
                d'autres ultérieurement)
              </span>
            </div>
            <v-row>
              <v-col cols="12" sm="6">
                <text-field
                  label="Enseigne*"
                  :field="form.company.addresses[0].name"
                  outlined
                  hint="Visible sur la planche photo et le site de l'ANTS"
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Adresse*"
                  :field="form.company.addresses[0].address"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Code postal*"
                  :field="form.company.addresses[0].postalCode"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Ville*"
                  :field="form.company.addresses[0].city"
                  outlined
                  dense
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <text-field
                  label="Téléphone"
                  :field="form.company.addresses[0].phone"
                  outlined
                  type="tel"
                  hint="Visible sur le site de l'ANTS"
                  dense
                ></text-field>
              </v-col>
            </v-row>
            <div class="subtitle-2">
              Horaires d'ouverture
              <span class="font-weight-regular">
                (ces horaires seront visibles sur le site de l'ANTS)
              </span>
            </div>
            <div
              v-for="(openingTime, index) of form.company.addresses[0]
                .openingTimes"
              :key="index"
              class="d-flex mt-2"
            >
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    label="Jour de la semaine*"
                    v-model="openingTime.dayName"
                    :error-messages="getErrors(openingTime.dayName.errors)"
                    :items="weekDays"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    :ref="'openHourMenu' + index"
                    v-model="openHourMenus[index]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="openingTime.openHour.value"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <text-field
                        :field="openingTime.openHour"
                        label="Heure d'ouverture*"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                      ></text-field>
                    </template>
                    <v-time-picker
                      v-if="openHourMenus[index]"
                      v-model="openingTime.openHour.value"
                      full-width
                      format="24hr"
                      @click:minute="
                        $refs['openHourMenu' + index][0].save(
                          openingTime.openHour.value,
                        )
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    :ref="'closeHourMenu' + index"
                    v-model="closeHourMenus[index]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="openingTime.closeHour.value"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <text-field
                        :field="openingTime.closeHour"
                        label="Heure de fermeture*"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                      ></text-field>
                    </template>
                    <v-time-picker
                      v-if="closeHourMenus[index]"
                      v-model="openingTime.closeHour.value"
                      full-width
                      format="24hr"
                      @click:minute="
                        $refs['closeHourMenu' + index][0].save(
                          openingTime.closeHour.value,
                        )
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <div>
                <v-btn
                  icon
                  class="mt-0"
                  :disabled="form.company.addresses[0].openingTimes.length < 2"
                  @click="removeOpeningTime(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex mt-2">
              <v-btn small class="ml-auto mr-9" @click="addOpeningTime">
                <v-icon left small>mdi-plus</v-icon>
                Ajouter un jour
              </v-btn>
            </div>
          </v-form>
          <div>
            <v-checkbox v-model="acceptICAO" :error-messages="acceptICAOError">
              <template #label>
                <span
                  >Conformément aux règles définies par l'ANTS, j'accepte de
                  prendre les photos de mes clients uniquement avec un appareil
                  photo (pas de smartphone) et de respecter les
                  <a @click.stop href="/img/icao.png" target="_blank"
                    >normes ICAO</a
                  >. Je reconnais également avoir lu et approuvé les
                  <a @click.stop href="/CGU.htm" target="_blank">CGU</a> de
                  Focalia.</span
                >
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            @click="submit"
            class="ml-auto"
            :loading="loading"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="text-center mt-1">
        Une question ? Contactez-nous via
        <a href="mailto:contact@focalia.fr">contact@focalia.fr</a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TextField from '@/components/form/TextField'
import Validation from '@/components/mixins/Validation'
import { requestService } from '@/services/request.service'

export default {
  name: 'Register',
  components: { TextField },
  mixins: [Validation],
  data() {
    return {
      form: {
        email: {
          value: '',
          required: true,
          isEmail: true,
        },
        password: {
          value: '',
          required: true,
        },
        company: {
          name: {
            value: '',
            required: true,
          },
          siret: {
            value: '',
            required: true,
          },
          contactEmail: {
            value: '',
            isEmail: true,
            required: true,
          },
          website: {
            value: '',
          },
          ownerFirstName: {
            value: '',
            required: true,
          },
          ownerLastName: {
            value: '',
            required: true,
          },
          kbis: {
            value: [],
          },
          addresses: [
            {
              name: {
                value: '',
                required: true,
              },
              address: {
                value: '',
                required: true,
              },
              postalCode: {
                value: '',
                type: 'number',
                required: true,
              },
              city: {
                value: '',
                required: true,
              },
              country: {
                value: 'FR',
              },
              phone: {
                value: '',
              },
              openingTimes: [
                {
                  dayName: {
                    value: '',
                    required: true,
                  },
                  openHour: {
                    value: '',
                    required: true,
                  },
                  closeHour: {
                    value: '',
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      },
      weekDays: [
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche',
      ],
      openHourMenus: [],
      closeHourMenus: [],
      documentUid: null,
      documentErrors: [],
      loading: false,
      kbisFile: {
        value: [],
      },
      acceptICAO: false,
      acceptICAOError: '',
    }
  },
  methods: {
    addOpeningTime() {
      this.form.company.addresses[0].openingTimes.push({
        dayName: {
          value: '',
        },
        openHour: {
          value: '',
        },
        closeHour: {
          value: '',
        },
      })
    },
    removeOpeningTime(index) {
      this.form.company.addresses[0].openingTimes.splice(index, 1)
    },
    submit(e) {
      if (e) {
        e.preventDefault()
      }
      this.formValidation(this.form, true)
      this.acceptICAOError = ''
      if (!this.acceptICAO) {
        return (this.acceptICAOError = 'Vous devez accepter les normes')
      }
      if (this.errorsNbr > 0) {
        return
      }
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.kbisFile.value)
      requestService
        .post('/api/user/upload-document', formData)
        .then(response => {
          this.form.company.kbis.value = response.uid
        })
        .catch(({ error, validationFailed }) => {
          this.loading = false
          if (validationFailed) {
            this.$set(
              this.form.company.kbis,
              'errors',
              error.errors.children.file.errors,
            )
          }
          return Promise.reject()
        })
        .then(() => {
          console.log('uid', this.form.company.kbis.value)
          const url = this.$route.query.precreate
            ? '/api/user/?precreate'
            : '/api/user/'
          return requestService
            .post(url, this.formatDataToSend(this.form))
            .then(response => {
              if (!response.success) {
                return
              }
              if (!response.emailVerified) {
                return this.$router.push({
                  name: 'ConfirmEmail',
                  query: { email: response.email },
                })
              } else if (!response.accountVerified) {
                return this.$router.push({
                  name: 'WaitingApproval',
                })
              }
            })
        })
        .catch(error => {
          console.log(error)
          if (error && error.validationFailed) {
            this.handleServerErrors(error.error, this.form)
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
