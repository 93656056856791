<template>
  <!--  change to clipped-right to let the left menu on the top left -->
  <v-app-bar :clipped-left="true" app color="secondary" dark dense>
    <v-app-bar-nav-icon @click.stop="$emit('switch-left-menu')" />
    <div
      class="ml-0 pl-4 d-flex align-center justify-center align-self-center mx-auto"
      style="position: absolute;width: 100%;left: 0;z-index: -1"
    >
      <router-link :to="{ name: 'Home' }" class="hidden-sm-and-down">
        <v-img
          src="/img/FocaliaBlanc.png"
          alt="Vuetify"
          width="5rem"
          contain
          position="left center"
        />
      </router-link>
    </div>
    <v-btn text class="ml-auto" @click="$root.$emit('help')">Aide</v-btn>
    <v-btn icon @click="toggleFullscreen">
      <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
      <v-icon v-else>mdi-fullscreen</v-icon>
    </v-btn>
    <v-btn
      icon
      @click.stop="$emit('switch-history-menu')"
      v-if="$route.meta.client !== true"
    >
      <v-icon>mdi-history</v-icon>
    </v-btn>
    <!--    <v-menu left bottom offset-y :min-width="200" z-index="450">
          <template v-slot:activator="{ on }">
            <v-btn icon large v-on="on">
              <v-icon>fa-user-circle</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title v-if="$subReady['Meteor.users.profile'] && currentUser">
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </v-card-title>
            <v-divider></v-divider>
            <v-list>
              <v-list-item link @click="logout">
                <v-list-item-icon>
                  <v-icon>fa-sign-out-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>-->
    <v-progress-linear
      :active="$store.state.loading"
      :indeterminate="$store.state.loading"
      absolute
      bottom
      color="yellow"
      v-show="$store.state.loading"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderBar',
  data() {
    return {
      isHiddenActionsVisible: false,
      isFullscreen: false,
    }
  },
  methods: {
    toggleFullscreen() {
      if (!document.fullscreenEnabled) {
        return
      }
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        document.body.requestFullscreen()
      }
    },
  },
  mounted() {
    document.addEventListener('fullscreenchange', event => {
      this.isFullscreen = !!document.fullscreenElement
    })
  },
}
</script>

<style scoped></style>
