<template>
  <!--  change to clipped-right to let the left menu on the top left -->
  <v-app-bar clipped-left app color="secondary" dark dense>
    <v-toolbar-title
      class="ml-0 pl-4 d-flex align-center justify-center align-self-center"
      style="width: 100%"
    >
      <router-link :to="{ name: 'Home' }" class="">
        <v-img
          src="/img/FocaliaBlanc.png"
          alt="Vuetify"
          width="5rem"
          contain
          position="left center"
        />
      </router-link>
    </v-toolbar-title>
    <v-btn icon @click="toggleFullscreen" absolute right class="d-none">
      <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
      <v-icon v-else>mdi-fullscreen</v-icon>
    </v-btn>

    <v-progress-linear
      :active="$store.state.loading"
      :indeterminate="$store.state.loading"
      absolute
      bottom
      color="yellow"
      v-show="$store.state.loading"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
export default {
  name: 'LoggedOutHeaderBar',
  data() {
    return {
      isFullscreen: false,
    }
  },
  methods: {
    toggleFullscreen() {
      if (!document.fullscreenEnabled) {
        return
      }
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        document.body.requestFullscreen()
      }
    },
  },
  mounted() {
    document.addEventListener('fullscreenchange', event => {
      this.isFullscreen = !!document.fullscreenElement
    })
  },
}
</script>

<style scoped></style>
