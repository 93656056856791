<template>
  <v-dialog width="500px" v-bind="$attrs" v-on="$listeners">
    <v-card tile>
      <v-card-title>Aide</v-card-title>
      <v-divider></v-divider>
    </v-card>
    <v-expansion-panels tile accordion v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Comment créer une planche photo ?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol>
            <li>L'utilisation du navigateur Google Chrome est recommandée.</li>
            <li>
              Depuis l'Editeur (la page principale), commencez par importer la
              photo de votre client en cliquant dans le cadre gris à gauche.
            </li>
            <li>
              Vous pouvez si besoin recadrer la photo en faisant glisser les
              poignées
              <v-icon>mdi-vector-square</v-icon>
              et en déplaçant le cadre (seul le cadre peut être déplacé, sans
              dépasser les bords de la photo). <br />
              Vous pouvez également ajuster les paramètres de votre photo
              (exposition...) depuis votre l'onglet OPTIMISATIONS.
            </li>
            <li>
              Sélectionnez l'usage auquel la photo est destinée depuis l'onglet
              USAGE (indispensable pour l'ANTS).
            </li>
            <li>
              Pour ajouter la signature de votre client vous pouvez soit :
              <ul>
                <li>
                  Utiliser un appareil tactile distant (voir ci-dessous "Comment
                  connecter un appareil distant pour la signature ?")
                </li>
                <li>
                  Signer directement sur cet appareil s'il dispose d'un écran
                  tactile ou via votre souris
                </li>
                <li>
                  Importer l'image de la signature si vous l'avez acquise via un
                  autre moyen (521 x 134 px)
                </li>
              </ul>
            </li>
            <li>
              Si vous souhaitez envoyer à votre client automatiquement le code
              ephoto qui sera généré, vous pouvez demander à votre client de
              saisir son adresse email en même temps que sa signature sur
              l'appareil distant (en activant "Demander l'adresse email du
              client") ou la saisir vous-même dans le champ sous la planche.
            </li>
            <li>
              Lorsque tout est prêt, cliquez sur le bouton Valider. La photo et
              la signature seront envoyées au serveur du collecteur ANTS puis un
              code ephoto sera ajouté sur la planche.
            </li>
            <li>
              Vous pouvez maintenant imprimer la planche. En fonction des
              réglages de votre navigateur, il est parfois plus simple
              d'exporter préalablement la photo (en cliquant sur Exporter).
            </li>
            <li>
              Une fois validée, une planche ne peut plus être modifiée. Si vous
              souhaiter tout de même y apporter des changements, vous pouvez la
              dupliquer en cliquant sur Dupliquer. Un nouveau code ephoto devra
              être généré.
            </li>
            <li>
              À tout moment, vous pouvez créer une autre planche en cliquant sur
              le bouton "Créer une autre planche" dans le cadre de validation.
              Si votre document en cours n'est pas terminé, il vous sera proposé
              de l'enregistrer en brouillon sur cet appareil (maximum 5
              documents) et d'y revenir plus tard en cliquant sur le bouton
              <v-icon>mdi-history</v-icon> dans la barre du haut.
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Comment connecter un appareil distant pour la signature ?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol>
            <li>
              Ouvrez le navigateur de votre appareil disposant d'un écran
              tactile (de préférence une tablette). Cet appareil doit être
              connecté à internet.
            </li>
            <li>
              Depuis ce dernier, rendez-vous sur Focalia (<a
                href="https://studio.focalia.fr"
                >https://studio.focalia.fr</a
              >) et connectez-vous à votre compte.
            </li>
            <li>
              Si vous disposez de plusieurs adresses ou plusieurs licences, il
              vous sera demandé de choisir laquelle associer à cet appareil.
            </li>
            <li>
              Ouvrez le menu en cliquant sur
              <v-icon>mdi-menu</v-icon>
              dans la barre du haut, à gauche.
            </li>
            <li>
              Cliquez sur "Mode signature". Cela ouvre une page destinée à être
              présentée à vos clients lorsque vous souhaitez récolter leur
              signature. <br />
              Par mesure de sécurité, la première fois il vous sera demandé de
              choisir un code PIN pour éviter que votre client puisse sortir du
              mode signature. Vous aurez donc besoin de ce code PIN lorsque vous
              souhaiterez quitter ce mode en cliquant sur Quitter dans le menu
              <v-icon>mdi-menu</v-icon>
              .
            </li>
            <li>
              Votre appareil est maintenant prêt. <br />
              Lorsque vous souhaiterez faire signer votre client, ouvrez cette
              page puis depuis votre PC sur l'éditeur photo (la page
              principale), cliquez sur le bouton
              <v-btn color="primary" class="my-1" small>
                <v-icon left>mdi-access-point</v-icon>
                Sur l'appareil distant
              </v-btn>
              dans le cadre de signature. <br />
              Cela ouvrira automatiquement une zone sur la tablette permettant à
              votre client de dessiner sa signature. La signature est alors
              transmise en temps réel sur votre PC. <br />
              En cas de problème, vérifiez que les deux appareils sont bien
              connectés à internet et actualisez les pages.
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-dialog>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {
      panel: null,
    }
  },
}
</script>

<style scoped>
li {
  margin-bottom: 10px;
}
</style>
