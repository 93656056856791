import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Licences from '../views/Licences.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Document' },
  },
  {
    path: '/document/:id?',
    name: 'Document',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'logged-out',
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'logged-out',
    },
  },
  {
    path: '/licences',
    name: 'Licences',
    component: () =>
      import(/* webpackChunkName: "licences" */ '../views/Licences.vue'),
  },
  {
    path: '/onboard-licences',
    name: 'OnboardLicences',
    component: () =>
      import(/* webpackChunkName: "licences" */ '../views/Licences.vue'),
    meta: {
      layout: 'logged-out',
    },
  },
  {
    path: '/device',
    name: 'Device',
    component: () =>
      import(/* webpackChunkName: "device" */ '../views/Device.vue'),
  },
  {
    path: '/sign',
    name: 'Sign',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "draw" */ '../views/Sign.vue'),
    meta: {
      client: true,
    },
  },
  {
    path: '/confirm-email/:key?',
    name: 'ConfirmEmail',
    component: () =>
      import(
        /* webpackChunkName: "confirm-email" */ '../views/ConfirmEmail.vue'
      ),
    meta: {
      layout: 'logged-out',
    },
  },
  {
    path: '/waiting-approval',
    name: 'WaitingApproval',
    component: () =>
      import(
        /* webpackChunkName: "waiting-approval" */ '../views/WaitingApproval.vue'
      ),
    meta: {
      layout: 'logged-out',
    },
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () =>
      import(
        /* webpackChunkName: "forget-password" */ '../views/ForgetPassword.vue'
      ),
    meta: {
      layout: 'logged-out',
    },
  },
  {
    path: '/link-connect/:key',
    name: 'LinkConnect',
    component: () =>
      import(/* webpackChunkName: "link-connect" */ '../views/LinkConnect.vue'),
    meta: {
      layout: 'logged-out',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  store.state.loading = true
  const publicPages = [
    'Login',
    'Register',
    'ConfirmEmail',
    'WaitingApproval',
    'ForgetPassword',
    'LinkConnect',
  ]
  const authRequired = !publicPages.includes(
    typeof to.name === 'string' ? to.name : '',
  )
  const loggedIn = localStorage.getItem('user')

  //console.log(await this.$store.dispatch("account/isGranted", to.meta.roles));
  if (authRequired && !loggedIn) {
    return next({ name: 'Login' })
  }
  /*else if(to.meta.roles !== undefined && !await this.$store.dispatch("account/isGranted", to.meta.roles)){
    return next("/");
  }*/

  document.title = 'Focalia - Studio'

  next()
})

router.afterEach(() => {
  store.state.loading = false
})

export default router
